@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Montserrat:ital,wght@0,200;0,300;0,500;1,200&family=Sen:wght@400;700;800&display=swap");

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

body {
  padding: 0;
  margin: 0;

}

.main-content {
  flex-grow: 1; /* Make the main-content flex item grow to fill the available space */
  padding-top: 10vh;
}

:root {
  --background-color: #ffffff;
  --font-color1: #2b2d42;
  --font-color2: #8d99ae;
  --font-highlight: #BCA4A4;
  --accent-color: #d90429;
}

h1, span {
  font-family: "Josefin Sans", Arial, Helvetica, sans-serif;
  font-size: 30px;
  font-weight: 300;
  color: var(--font-color1);
}

* {
  font-family: "Sen", Arial, Helvetica, sans-serif;
  color: var(--font-color1);
  background: var(--background-color);
}

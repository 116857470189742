.footer {
  height: 54px;
  background: var(--background-color);
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-top: 1px solid #2b2d4278;
  margin-left: 100px;
  margin-right: 100px;
}

.socialMedia svg {
  color: var(--font-color2);
  margin: 20px;
  font-size: 70px;
  cursor: pointer;
}

.footer p {
  color: var(--font-color1);
  margin: 20px;
  font-size: 12px;
}

@media only screen and (max-width: 600px) {
  .footer svg {
    font-size: 36px;
  }

  .footer {
    height: 80px;
    background: var(--background-color);
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-top: 1px solid var(--font-color1);
    margin-left: 24px;
    margin-right: 24px;
  }

  .footer p {
    margin: 10px
  }

  .email {
    display: none;
  }
}

@media (max-width: 800px) {
}

@media (max-width: 400px) {


}


form {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 25vw;
  font-size: 16px;
}

input {
  width: 100%;
  height: 35px;
  padding: 7px;
  outline: none;
  border-radius: 5px;
  border: 1px solid rgb(220, 220, 220);

  &:focus {
    border: 2px solid var(--font-highlight);
  }
}

textarea {
  max-width: 100%;
  min-width: 100%;
  width: 100%;
  max-height: 100px;
  min-height: 100px;
  padding: 7px;
  outline: none;
  border-radius: 5px;
  border: 1px solid rgb(220, 220, 220);

  &:focus {
    border: 2px solid var(--font-highlight);
  }
}

label {
  margin-top: 1rem;
}

input[type="submit"] {
  margin-top: 2rem;
  cursor: pointer;
  background: var(--accent-color);
  color: white;
  border: none;
}

@media (max-width: 800px) {
  form {
    width: 80vw;
  }
}

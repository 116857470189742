.projects {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  margin-top: 10vh;
  margin-right: 2vw;
  margin-left: 2vw;
  margin-bottom: 2vh;
}


/* MENU ITEM STYLING */

.projectItem {
  position: relative;
  display: inline-block;
  margin-bottom: 12px;
}

.imageContainer {
  position: relative;
  overflow: hidden;
}

.projectItem:hover {
  transition: 0.3s ease-in;
  cursor: pointer;
}

.projectItem .bgImage {
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
  cursor: pointer;
  display: block;
}

.overlay {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 100%;
  background-color: #ffffffba; /* Adjust the opacity as desired */
  opacity: 0;
  transition: width 0.4s, opacity 0.4s;
}

.projects .projectTitle {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: var(--font-color1);
  background-color: transparent;
  opacity: 0;
  transition: opacity 0.4s;
  font-weight: 400;
  font-size: 24px;
}


.projectItem:hover .overlay {
  width: 100%;
  opacity: 1;
}

.projectItem:hover .projectTitle {
  opacity: 1;
}

.projectItem h1 {
  font-size: 20px;
}

@media only screen and (max-width: 991px) {
  .projects {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    padding: 0 12px;
  }

}

@media (max-width: 480px) {
  .projects {
  -webkit-column-count: 1;
  -moz-column-count: 1;
  column-count: 1;
  -webkit-column-width: 100%;
  -moz-column-width: 100%;
  column-width: 100%;
  margin-bottom: 1vh;
  margin-left: 0;
  margin-right: 0;
  }

  .projectItem {
    height: auto;
  }
}


.modalBackground {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContainer {
  width: 250px;
  border-radius: 12px;
  background-color: var(--background-color);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 25px;
}

.modalContainer title {
  display: inline-block;
  text-align: center;
  margin-top: 10px;
}

.modalBody {
  font-size: 12px;
}

.titleCloseBtn {
  display: flex;
  justify-content: flex-end;
}

.titleCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
  color: var(--accent-color)
}

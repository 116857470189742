.navbar {
  position: fixed;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100px;
  background: var(--background-color);
}

.links {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2vw;
}

.logo {
  margin: 20px 0 20px 20px
}

.navbar a {
  color: var(--font-color1);
  text-decoration: none;
  margin: 14px 0;
  font-size: 18px;

}

.navbar-elements {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 2vw;
  margin-right: 2vw;
}

span {
  color: var(--accent-color);
}

.toggleButton {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.toggleButton svg {
  font-size: 50px;
}
.toggleButton button {
  margin-right: 12px;
  padding: 0;
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
}

#open {
  display: flex;
  height: 100vh;
  align-items: left;
  flex-direction: column;
  margin-left: 1vw;
}

#open .links {
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 50px;
  margin-left: 20px;
}


#open a {
  width: 100%;
  text-align: left;
}

#open .logo {
  display: none;
}


@media only screen and (max-width: 900px) {
  .navbar a {
  }
}

@media only screen and (max-width: 600px) {
  .toggleButton {
    display: flex;
    justify-content: space-between;
  }
  #close a {
    display: none;
  }
  #close .logo {
    display: none;
  }
  .logo-small span {
    font-size: 20px;
  }

  .logo-small {
    font-size: 20px;
    margin-left: 7.5vw;
  }
}

@media only screen and (min-width: 600px) {
  .toggleButton {
    display: none;
  }

  .hiddenLinks {
    display: none;
  }

  #open {
    height: 100px;
  }

  #open .links {
    flex-direction: row;
  }

  #open .logo {
    display: none;

}
}

.gallery {
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
  padding: 0 12px;
}

.gallery .pics {
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
  cursor: pointer;
  margin-bottom: 12px;
}

.gallery .pics:hover {
  filter: opacity(.8);
}

.project {
  margin-right: 2vw;
  margin-left: 2vw;
  margin-bottom: 2vh;
}

.project-info {
  text-align: left;
}

.project-info h1 {
  font-size: 32px;
}

.project-info h3 {
  font-size: 1.1em;
  margin-block-start: 0;
  margin-block-end: 0.2em;
  font-weight: 50;
  letter-spacing: 0.05em;
}

.project-info p {
  margin-block-start: 1em;
    margin-block-end: 0;
}

/*model*/

.model {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  transition: opacity .4s ease, visibility .4s ease, transform .5s ease-in-out;
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
  overflow: hidden;
  z-index: 999;
}

.model.open {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}

.model img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 20px 0 20px;
  margin: 0 auto;
}

.model.open svg {
  position: fixed;
  top: 10px;
  right: 10px;
  width: 2rem;
  height: 2rem;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.4);
  color: #ffffff;
  cursor: pointer;
}

@media (min-width: 991px) {
  .project {
    display: flex;
    margin-top: 10vh;
    gap: 2vw;
  }

  .project-info {
    max-width: 25vw;
    min-width: 20vw;
  }
}

@media (max-width: 480px) {
  .gallery {
  -webkit-column-count: 1;
  -moz-column-count: 1;
  column-count: 1;
  -webkit-column-width: 100%;
  -moz-column-width: 100%;
  column-width: 100%;
  }

  .project {
    align-items: center;
  }

  .project-info h1 {
    margin-top: 50px;
  }
}

@media (max-width: 800px) {
  .gallery {

  }

  .project {
    align-items: center;
  }

  .project-info {
    padding: 0px 12px;
  }
}
